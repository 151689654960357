import { HashRouter, Route, Routes} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Login from './components/login';
import Acl from './components/layout';
import Carousel from './components/Carousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/login.css';
import Agent from './components/edge'

function App() {

  // const [state,setState]=useState(initialState);
 
  return (
    <>
      <ToastContainer position="top-center" style={{width: "29rem"}} className="toastAlert"/>
      <HashRouter >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/ipruedge" element={<Agent />}/>
          <Route path="/Acl" element={<Acl />} >
            <Route path="vintage" element={<Carousel />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  )
}

export default App
