import Carousel from 'react-bootstrap/Carousel';
import VintageMange from '../components/vintageManager';
import YuvaManager from '../components/yuvaManager';
import { Container } from 'react-bootstrap';
import { useState, useEffect, createContext, useContext } from "react";
import { Link, Redirect, useNavigate, useHref, useHistory } from "react-router-dom";
import { JSEncrypt } from "jsencrypt";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/bootstrap.min.css';
import '../css/login.css';
import VintageManager from '../components/vintageManager';
import NetInfo from '@react-native-community/netinfo';
import * as alertify from 'alertifyjs';
import {format} from 'date-fns';


const Reload = () => {

  const yuvaData = sessionStorage.getItem('yuva');

  const topYuva = JSON.parse(yuvaData);


  if (topYuva) {
    return {
      TopYuvas: topYuva,

    }
  } else {
    return {
      TopYuvas: null,

    }
  }
}

function IndividualIntervalsExample() {

  const navigate = useNavigate();
  const [showVintage, setShowVintage] = useState(true);
  const [showYuva, setShowYuva] = useState(false);
  const [refreshToken, setToken] = useState('')


  const toggleComponents = () => {
    setShowVintage(prevState => !prevState);
    setShowYuva(prevState => !prevState);

  }

  useEffect(() => {
    const interval = setInterval(() => {
      // connection()
      toggleComponents();
      // connection();
    }, 14000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      submitHandle();
    }, 600000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    window.onpopstate = function (event) {
      navigate.go(1);
    };
  }, [])

  // const [isOnline, setIsOnline] = useState(true);

  // useEffect(() => {
  //   const connection = NetInfo.addEventListener(state => {
  //     setIsOnline(state.isConnected);
  //   });

  //   return () => {
  //     connection();
  //   };
  // }, []);

  const backToHome = (e) => {
    // e.preventDefault();
    // sessionStorage.clear();
    // window.location.reload();
    // <Redirect to={window.location.pathname} />
    navigate('/#')
  }

  // const connection = () => {
  //   NetInfo.addEventListener(state => {
  //     setIsOnline(false);
  //     console.log("Connection:");
  //     if (!state.isConnected) {
  //       var CurrDate = format(new Date(),'dd MMM yyyy, h:mm:ss a')
  //       var msg = "The internet connection was interrupted on " + CurrDate +". Please log in again";
  //       toast.error(msg, {
  //         closeButton: backToHome(),
  //         closeOnClick: false,
  //         autoClose: false,
          
  //       })
  //     }
  //   });

  // }
  // console.log("Connection:", isOnline);

  const getBranchDetails = async (jwt) => {

    var encrypt = new JSEncrypt();
    var publicKey = `${process.env.REACT_APP_PUBLICKEY}`;


    encrypt.setPublicKey(publicKey);

    var currTimestamp = Date.now();
    var time = "am";
    var ntid = window.sessionStorage.getItem('id');
    var ntID = JSON.parse(ntid);


    var branchCode = window.sessionStorage.getItem('br');
    var brCode = JSON.parse(branchCode);

    var encData = encrypt.encrypt(JSON.stringify({
      "userid": ntID,
      "timestamp": currTimestamp,
      "branchCode": brCode,
      "time": time
    }));

    const Response = await axios.post(`${process.env.REACT_APP_API_ADVMYM_URL}`, JSON.stringify({ ntID, encData }), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwt}`
      },

    })
      .then((Response) => {
        console.log("Response Bname:", Response.data);
        window.sessionStorage.setItem('vintage', JSON.stringify(Response.data.finalTopManagers.top_vintages));
        window.sessionStorage.setItem('yuva', JSON.stringify(Response.data.finalTopManagers.top_yuvas));
        window.sessionStorage.setItem('branch', JSON.stringify(Response.data.finalTopManagers.BRANCH_NAME));
        window.sessionStorage.setItem('date', JSON.stringify(Response.data.finalTopManagers.DATE));
        window.sessionStorage.setItem('region', JSON.stringify(Response.data.finalTopManagers.REGION));
      }).catch((error) => {
        console.log("Error:", error)
        toast.error(error.response.data.message);
      })
  }



  const ACLData = async (Token) => {

    var encrypt = new JSEncrypt();
    var publicKey = `${process.env.REACT_APP_PUBLICKEY}`;

    encrypt.setPublicKey(publicKey);

    var currTimestamp = Date.now();

    var ntid = window.sessionStorage.getItem('id');
    var ntID = JSON.parse(ntid);

    var encData = encrypt.encrypt(JSON.stringify({
      "userid": ntID,
      "timestamp": currTimestamp
    }));


    const Response = await axios.post(`${process.env.REACT_APP_API_VMYM_URL}`, JSON.stringify({ ntID, encData }), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Token}`
      },

    })
      .then((Response) => {
        // setIsLoading(true){
        var data = Response.data;
        var vintageYuva = Response.data.finalTopManagers;
        var time = Response.data.time;

        if (vintageYuva && vintageYuva !== undefined && vintageYuva !== null) {
          window.sessionStorage.setItem('vintage', JSON.stringify(Response.data.finalTopManagers.top_vintages));
          window.sessionStorage.setItem('yuva', JSON.stringify(Response.data.finalTopManagers.top_yuvas));
          window.sessionStorage.setItem('branch', JSON.stringify(Response.data.finalTopManagers.BRANCH_NAME));
          window.sessionStorage.setItem('date', JSON.stringify(Response.data.finalTopManagers.DATE));
          window.sessionStorage.setItem('region', JSON.stringify(Response.data.finalTopManagers.REGION));
        }

      })
      .catch((error) => {
        // setIsLoading(true)
        console.log(error)

        if (error.response) {
          toast.error(error.response.data.message);
        }

        else {
          toast.error(error.message);
        }

      })

  }

  const submitHandle = async (e) => {

    var ntid = window.sessionStorage.getItem('id');
    var ntID = JSON.parse(ntid);
    var Cred = window.sessionStorage.getItem('cred');
    var TOKEN = JSON.parse(Cred);
    var Cred1 = window.sessionStorage.getItem('tkn');
    var TKN = JSON.parse(Cred1);

    // console.log("inside Refresh Token:",refreshToken);


    if (TKN && TKN !== null && TKN !== "" && TKN !== undefined) {
      var refreshtoken = TKN;
      setToken('');
      console.log("Refresh Token Genarated", refreshtoken);
    }
    if (TOKEN && TOKEN !== null && TOKEN !== "" && TOKEN !== undefined) {
      var refreshtoken = TOKEN
      sessionStorage.removeItem('cred');
      console.log("first Old Token", refreshtoken);
    }
    console.log("Outside Refresh Token:", refreshtoken);
    // console.log("Outside Refresh Token:",Token);

    const Response = await axios.post(`${process.env.REACT_APP_API_REFRESH_URL}`, JSON.stringify({ ntID }), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${refreshtoken}`
      },

    })
      .then((Response) => {
        console.log("Response:", Response)
        const token = Response.data.refresh_token;
        var TIME = window.sessionStorage.getItem('time');
        var Time = JSON.parse(TIME);
        console.log("TIME:", Time)

        if (token && token !== null && token !== undefined && token !== " " && Time === 'pm') {
          window.sessionStorage.setItem('tkn', JSON.stringify(token));
          setToken(token);
          ACLData(token);
        }
        else if (Time === 'am') {
          window.sessionStorage.setItem('tkn', JSON.stringify(token));
          setToken(token);
          getBranchDetails(token);
        }
        else {
          toast.error("Internal server error");
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }
  // console.log("Outside Refresh Token:",refreshToken);

  return (

    < div className="h-56 sm:h-64 xl:h-80 2xl:h-96" >
      {
        showVintage && <VintageManager />
      }
      {
        showYuva && <YuvaManager />
      }

    </div>

  );
}

export default IndividualIntervalsExample;
